exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eqa-scheme-js": () => import("./../../../src/pages/eqa-scheme.js" /* webpackChunkName: "component---src-pages-eqa-scheme-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovations-js": () => import("./../../../src/pages/innovations.js" /* webpackChunkName: "component---src-pages-innovations-js" */),
  "component---src-pages-ngs-about-js": () => import("./../../../src/pages/ngs/about.js" /* webpackChunkName: "component---src-pages-ngs-about-js" */),
  "component---src-pages-ngs-contact-js": () => import("./../../../src/pages/ngs/contact.js" /* webpackChunkName: "component---src-pages-ngs-contact-js" */),
  "component---src-pages-ngs-immunogenetics-js": () => import("./../../../src/pages/ngs/immunogenetics.js" /* webpackChunkName: "component---src-pages-ngs-immunogenetics-js" */),
  "component---src-pages-ngs-participants-js": () => import("./../../../src/pages/ngs/participants.js" /* webpackChunkName: "component---src-pages-ngs-participants-js" */),
  "component---src-pages-ngs-protocols-js": () => import("./../../../src/pages/ngs/protocols.js" /* webpackChunkName: "component---src-pages-ngs-protocols-js" */),
  "component---src-pages-ngs-publications-js": () => import("./../../../src/pages/ngs/publications.js" /* webpackChunkName: "component---src-pages-ngs-publications-js" */),
  "component---src-pages-ngs-steering-group-js": () => import("./../../../src/pages/ngs/steering-group.js" /* webpackChunkName: "component---src-pages-ngs-steering-group-js" */),
  "component---src-pages-ngs-workpackages-js": () => import("./../../../src/pages/ngs/workpackages.js" /* webpackChunkName: "component---src-pages-ngs-workpackages-js" */),
  "component---src-pages-participants-js": () => import("./../../../src/pages/participants.js" /* webpackChunkName: "component---src-pages-participants-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-support-diagnostic-support-js": () => import("./../../../src/pages/support/diagnostic-support.js" /* webpackChunkName: "component---src-pages-support-diagnostic-support-js" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-guidelines-js": () => import("./../../../src/pages/support/guidelines.js" /* webpackChunkName: "component---src-pages-support-guidelines-js" */),
  "component---src-pages-support-interpretation-tables-js": () => import("./../../../src/pages/support/interpretation-tables.js" /* webpackChunkName: "component---src-pages-support-interpretation-tables-js" */),
  "component---src-pages-trgassay-js": () => import("./../../../src/pages/trgassay.js" /* webpackChunkName: "component---src-pages-trgassay-js" */),
  "component---src-pages-workshops-information-js": () => import("./../../../src/pages/workshops/information.js" /* webpackChunkName: "component---src-pages-workshops-information-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-pages-workshops-list-js": () => import("./../../../src/pages/workshops/list.js" /* webpackChunkName: "component---src-pages-workshops-list-js" */)
}

